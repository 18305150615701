<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    // console.log('注释')
  },
};
</script>

<style>
/* 去除elementPlus选择显示蓝色边框 */
:deep(.el-select .el-input__wrapper) {
    box-shadow: none !important;
}

:deep(.el-input .el-input__wrapper.is-focus) {
    box-shadow: none !important;
}

:deep(.el-select .el-input.is-focus .el-input__wrapper) {
    border-color: #DCDFE6 !important;
    box-shadow: none !important;
}

* {
  padding: 0;
  margin: 0;
}

body {
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, "\5FAE\8F6F\96C5\9ED1", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
  margin: 0 !important;
}

.el-menu-item-group__title {
  padding: 0px 0 0px 0px;
  line-height: normal;
  font-size: 12px;
  color: #909399;
}

.el-header {
  position: relative;
}

.el-breadcrumb {
  position: absolute;
  top: 0;
  line-height: 60px;
  margin-left: 40px;
}

.el-pagination {
  margin-top: 40px;
  text-align: center;
  justify-content: center;
}

.el-form {
  background: #fff;
  padding: 14px 10px;
}

.el-cascader-panel {
  height: 250px;
}

/* .el-button--small {
  padding-left: 0 !important;
  padding-right: 0 !important;
} */
/* 清浮动 */
.clearfix:after {
  /*伪元素是行内元素 正常浏览器清除浮动方法*/
  content: "";
  display: block;
  clear: both;
}

.clearfix {
  zoom: 1;
}

@media screen and (max-width: 700px) {
  @charset "utf-8";

  .border,
  .border-top,
  .border-right,
  .border-bottom,
  .border-left,
  .border-topbottom,
  .border-rightleft,
  .border-topleft,
  .border-rightbottom,
  .border-topright,
  .border-bottomleft {
    position: relative;
  }

  .border::before,
  .border-top::before,
  .border-right::before,
  .border-bottom::before,
  .border-left::before,
  .border-topbottom::before,
  .border-topbottom::after,
  .border-rightleft::before,
  .border-rightleft::after,
  .border-topleft::before,
  .border-topleft::after,
  .border-rightbottom::before,
  .border-rightbottom::after,
  .border-topright::before,
  .border-topright::after,
  .border-bottomleft::before,
  .border-bottomleft::after {
    content: "\0020";
    overflow: hidden;
    position: absolute;
  }

  /* border
 * 因，边框是由伪元素区域遮盖在父级
 * 故，子级若有交互，需要对子级设置
 * 定位 及 z轴
 */
  .border::before {
    box-sizing: border-box;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #eaeaea;
    transform-origin: 0 0;
  }

  .border-top::before,
  .border-bottom::before,
  .border-topbottom::before,
  .border-topbottom::after,
  .border-topleft::before,
  .border-rightbottom::after,
  .border-topright::before,
  .border-bottomleft::before {
    left: 0;
    width: 100%;
    height: 1px;
  }

  .border-right::before,
  .border-left::before,
  .border-rightleft::before,
  .border-rightleft::after,
  .border-topleft::after,
  .border-rightbottom::before,
  .border-topright::after,
  .border-bottomleft::after {
    top: 0;
    width: 1px;
    height: 100%;
  }

  .border-top::before,
  .border-topbottom::before,
  .border-topleft::before,
  .border-topright::before {
    border-top: 1px solid #eaeaea;
    transform-origin: 0 0;
  }

  .border-right::before,
  .border-rightbottom::before,
  .border-rightleft::before,
  .border-topright::after {
    border-right: 1px solid #eaeaea;
    transform-origin: 100% 0;
  }

  .border-bottom::before,
  .border-topbottom::after,
  .border-rightbottom::after,
  .border-bottomleft::before {
    border-bottom: 1px solid #eaeaea;
    transform-origin: 0 100%;
  }

  .border-left::before,
  .border-topleft::after,
  .border-rightleft::after,
  .border-bottomleft::after {
    border-left: 1px solid #eaeaea;
    transform-origin: 0 0;
  }

  .border-top::before,
  .border-topbottom::before,
  .border-topleft::before,
  .border-topright::before {
    top: 0;
  }

  .border-right::before,
  .border-rightleft::after,
  .border-rightbottom::before,
  .border-topright::after {
    right: 0;
  }

  .border-bottom::before,
  .border-topbottom::after,
  .border-rightbottom::after,
  .border-bottomleft::after {
    bottom: 0;
  }

  .border-left::before,
  .border-rightleft::before,
  .border-topleft::after,
  .border-bottomleft::before {
    left: 0;
  }
}

@media (max--moz-device-pixel-ratio: 1.49),
(-webkit-max-device-pixel-ratio: 1.49),
(max-device-pixel-ratio: 1.49),
(max-resolution: 143dpi),
(max-resolution: 1.49dppx) {
  /* 默认值，无需重置 */
}

@media (min--moz-device-pixel-ratio: 1.5) and (max--moz-device-pixel-ratio: 2.49),
(-webkit-min-device-pixel-ratio: 1.5) and (-webkit-max-device-pixel-ratio: 2.49),
(min-device-pixel-ratio: 1.5) and (max-device-pixel-ratio: 2.49),
(min-resolution: 144dpi) and (max-resolution: 239dpi),
(min-resolution: 1.5dppx) and (max-resolution: 2.49dppx) {
  .border::before {
    width: 200%;
    height: 200%;
    transform: scale(.5);
  }

  .border-top::before,
  .border-bottom::before,
  .border-topbottom::before,
  .border-topbottom::after,
  .border-topleft::before,
  .border-rightbottom::after,
  .border-topright::before,
  .border-bottomleft::before {
    transform: scaleY(.5);
  }

  .border-right::before,
  .border-left::before,
  .border-rightleft::before,
  .border-rightleft::after,
  .border-topleft::after,
  .border-rightbottom::before,
  .border-topright::after,
  .border-bottomleft::after {
    transform: scaleX(.5);
  }
}

@media (min--moz-device-pixel-ratio: 2.5),
(-webkit-min-device-pixel-ratio: 2.5),
(min-device-pixel-ratio: 2.5),
(min-resolution: 240dpi),
(min-resolution: 2.5dppx) {
  .border::before {
    width: 300%;
    height: 300%;
    transform: scale(.33333);
  }

  .border-top::before,
  .border-bottom::before,
  .border-topbottom::before,
  .border-topbottom::after,
  .border-topleft::before,
  .border-rightbottom::after,
  .border-topright::before,
  .border-bottomleft::before {
    transform: scaleY(.33333);
  }

  .border-right::before,
  .border-left::before,
  .border-rightleft::before,
  .border-rightleft::after,
  .border-topleft::after,
  .border-rightbottom::before,
  .border-topright::after,
  .border-bottomleft::after {
    transform: scaleX(.33333);
  }
}</style>
