import { createStore } from 'vuex'

const store = createStore({
  state() {
    return {
      // 消息数量
      NumberMessages: 0
    }
  },
  getters: {
    getNumMessage(state) {
      return state.NumberMessages
    },
  },
  mutations: {
    useNumberMessages(state, data) {
      state.NumberMessages = data
    }
  },
  actions: {
    useNumberMessages(context, data) {
      context.commit('useNumberMessages', data)
    }
  }
})

export default store