import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router' //路由
// import Vue from "vue";
// elementui 组件

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/lib/locale/lang/zh-cn' //将默认英文组件改成中文
import * as ElIcons from '@element-plus/icons-vue'
import print from 'vue3-print-nb'
import NutUI from "@nutui/nutui";
import Varlet from '@varlet/ui'
// 采用按需加载时  此全局css样式，需要删除
import "@nutui/nutui/dist/style.css";
import '@varlet/ui/es/style'
import vueEsign from 'vue-esign'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import "@/style/public.css";

// import "@/utils/directives.js"

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
for (const iconName in ElIcons) {
    app.component(iconName, ElIcons[iconName])
}
// app.config.globalProperties
app.config.globalProperties.resetSetItem  = function (key, newVal) {
    // 创建 StorageEvent 事件
    let newStorageEvent = document.createEvent("StorageEvent");
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
  
        // 初始化 StorageEvent 事件
        newStorageEvent.initStorageEvent(
          "setItem", // 事件别名
          false,
          false,
          k,
          null,
          val,
          null,
          null
        );
  
        // 派发事件
        window.dispatchEvent(newStorageEvent);
      },
    };
    return storage.setItem(key, newVal);
  };

app.use(ElementPlus, { locale })
app.use(store)
app.use(router)
app.use(print)
app.use(NutUI)
app.use(Varlet)
app.use(vueEsign)
app.mount('#app')
