import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";
import { ElMessage, ElMessageBox } from 'element-plus'
const routes = [{
    path: "/login",
    component: () =>
        import('../views/Login2'),
    meta: { title: '启新云方' },
},
{
    path: "/Phonecun",
    // beforeEnter:(to,from,next)=>{
    //     var u = navigator.userAgent;
    //     var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    //     // XXX: 修复iOS版微信HTML5 History兼容性问题
    //     if (isiOS && to.path !== location.pathname) {
    //       // 此处不可使用location.replace
    //       alert(to.fullPath,location.pathname)
    //     //   location.assign(to.fullPath)
    //     } else {
    //       next()
    //     }
    //   },
    component: () =>
        import('../views/PhoneYJ/Phonecun'),
    meta: { title: '启新云方' },
},
{
    path: "/Phonechuan",
    component: () =>
        import('../views/PhoneYJ/Phonechuan'),
    meta: { title: '启新云方' },
},
{
    path: "/Phonedizhi",
    component: () =>
        import('../views/PhoneYJ/Phonedizhi'),
    meta: { title: '启新云方' },
},
{
    path: "/Phoneuser",
    component: () =>
        import('../views/PhoneYJ/Phoneuser'),
    meta: { title: '启新云方' },
},
{
    path: "/Phonebiao",
    component: () =>
        import('../views/PhoneYJ/Phonebiao'),
    meta: { title: '启新云方' },
},
{
    path: "/Phonegai",
    component: () =>
        import('../views/PhoneYJ/Phonegai'),
    meta: { title: '启新云方' },
},
{
    path: "/Phonedigai",
    component: () =>
        import('../views/PhoneYJ/Phonedigai'),
    meta: { title: '启新云方' },
},
{
    path: "/Phonedan",
    component: () =>
        import('../views/PhoneYJ/Phonedan'),
    meta: { title: '启新云方' },
},
{
    path: "/doctor",
    component: () =>
        import('../views/shuoming'),
    meta: { title: '启新云方' },
},
{
    path: "/payList",
    component: () =>
        import('../views/payList'),
    meta: { title: '启新云方' },
},
{
    path: "/pay",
    component: () =>
        import('../views/pay'),
    meta: { title: '启新云方' },
},
{
    path: "/agent",
    component: () =>
        import('../views/shuoming2'),
    meta: { title: '启新云方' },
},
{
    path: "/institution",
    component: () =>
        import('../views/shuoming3'),
    meta: { title: '启新云方' },
},
{
    path: "/login2",
    component: () =>
        import('../views/Login2'),
    meta: { title: '启新云方' },
},
{
    path: "/Consultation",
    component: () =>
        import('../views/phone/Consultation'),
    meta: { title: '启新云方', selected: "/Consultation" }
},
{
    path: "/home",
    component: () =>
        // import('../views/Layout'),
        import('@/components/FrameLayout/Index'),
    meta: { title: '启新云方' },
    redirect: '/clinicRegisterInfo',
    children: [{
        path: "/drugList",
        component: () =>
            import('../views/drugadministration/durgList'),
        meta: { title: '启新云方', selected: "/drugList" }
    },
    {
        path: "/drugHousing",
        component: () =>
            import('../views/drugadministration/drugHousing'),
        meta: { title: '启新云方', selected: "/drugHousing" }
    },
    {
        path: "/Phonecode",
        component: () =>
            import('../views/PhoneYJ/Phonecode'),
        meta: { title: '启新云方' },
    },
    {
        path: "/Phonevip",
        component: () =>
            import('../views/PhoneYJ/Phonevip'),
        meta: { title: '启新云方' },
    },
    {
        path: "/Phoneinfo",
        component: () =>
            import('../views/PhoneYJ/Phoneinfo'),
        meta: { title: '启新云方' },
    },
    {
        path: "/Phonepcdan",
        component: () =>
            import('../views/PhoneYJ/Phonepcdan'),
        meta: { title: '启新云方' },
    },
    {
        path: "/extra",
        component: () =>
            import('../views/drugadministration/extra'),
        meta: { title: '启新云方', selected: "/extra" }
    },
    {
        path: "/lossreport",
        component: () =>
            import('../views/drugadministration/lossreport'),
        meta: { title: '启新云方', selected: "/lossreport" }
    },
    {
        path: "/stockManage",
        component: () =>
            import('../views/drugadministration/stockManage'),
        meta: { title: '启新云方', selected: "/stockManage" }
    },
    {
        path: "/dateEarning",
        component: () =>
            import('../views/drugadministration/dateEarning'),
        meta: { title: '启新云方', selected: "/dateEarning" }
    },
    {
        path: "/storageRecord",
        component: () =>
            import('../views/drugadministration/storageRecord'),
        meta: { title: '启新云方', selected: "/storageRecord" }
    },
    {
        path: "/crudedrugs",
        component: () =>
            import('../views/drugadministration/crudedrugs'),
        meta: { title: '启新云方', selected: "/crudedrugs" }
    },
    {
        path: "/drugList",
        component: () =>
            import('../views/drugadministration/durgList'),
        meta: { title: '启新云方', selected: "/drugList" }
    },
    {
        path: "/usermanagement",
        component: () =>
            import('../views/PermissionCenter/UserManagement'),
        meta: { title: '启新云方', selected: "/usermanagement" }
    },
    {
        path: "/RoutePermissions",
        component: () =>
            import('../views/PermissionCenter/RoutePermissions'),
        meta: { title: '启新云方', selected: "/RoutePermissions" }
    },
    {
        path: "/RightsManagement",
        component: () =>
            import('../views/PermissionCenter/RightsManagement'),
        meta: { title: '启新云方', selected: "/RightsManagement" }
    },
    {
        path: "/RoutePermissionsJG",
        component: () =>
            import('../views/PermissionCenter/RoutePermissionsJG'),
        meta: { title: '启新云方', selected: "/RoutePermissionsJG" }
    },
    {
        path: "/RightsManagementJG",
        component: () =>
            import('../views/PermissionCenter/RightsManagementJG'),
        meta: { title: '启新云方', selected: "/RightsManagementJG" }
    },
    {
        path: "/AnnouncementList",
        component: () =>
            import('../views/AnnouncementList/AnnouncementList'),
        meta: { title: '启新云方', selected: "/AnnouncementList" }
    },
    {
        path: "/addAnnouncement",
        component: () =>
            import('../views/AnnouncementList/addAnnouncement'),
        meta: { title: '启新云方', selected: "/addAnnouncement" }
    },
    {
        path: '/viewDetails/:message_id',
        component: () =>
            import('../views/message/viewDetails'),
        meta: { title: '启新云方', selected: "/viewDetails" }
    },
    {
        path: '/modifyDetails/:message_id',
        component: () =>
            import('../views/message/modifyDetails'),
        meta: { title: '启新云方HIS管理系统', selected: "/modifyDetails" }
    },
    {
        path: '/allMessage',
        component: () =>
            import('../views/message/allMessage'),
        meta: { title: '启新云方HIS管理系统', selected: "/allMessage" }
    },
    {
        path: "/institutionsList",
        component: () =>
            import('../views/organization/institutionsList'),
        meta: { title: '启新云方', selected: "/institutionsList" }
    },
    {
        path: "/orderCount",
        component: () =>
            import('../views/organization/orderCount'),
        meta: { title: '启新云方', selected: "/orderCount" }
    },
    {
        path: "/AreaList",
        component: () =>
            import('../views/organization/AreaList'),
        meta: { title: '启新云方', selected: "/AreaList" }
    },
    {
        path: "/agent_list",
        component: () =>
            import('../views/organization/agent'),
        meta: { title: '启新云方', selected: "/agent_list" }
    },
    {
        path: "/agent_listmaster",
        component: () =>
            import('../views/organization/agent_listmaster'),
        meta: { title: '启新云方', selected: "/agent_listmaster" }
    },
    {
        path: "/personManage",
        component: () =>
            import('../views/organization/personManage'),
        meta: { title: '启新云方', selected: "/personManage" }
    },
    {
        path: "/setAccountNum",
        component: () =>
            import('../views/organization/setAccountNum'),
        meta: { title: '启新云方', selected: "/setAccountNum" }
    },
    {
        path: "/list",
        component: () =>
            import('../views/OrderManagement/list2'),
        meta: { title: '启新云方', selected: "/list" }
    },
    {
        path: "/list2",
        component: () =>
            import('../views/OrderManagement/list'),
        meta: { title: '启新云方', selected: "/list2" }
    },
    //修改注释
    // {
    //     path: "/prescription",
    //     component: () =>
    //         import('../views/OrderManagement/prescription3'),
    //     meta: { title: '启新云方', selected: "/prescription3" }
    // },
    {
        path: "/prescription2",
        component: () =>
            import('../views/OrderManagement/prescription2'),
        meta: { title: '启新云方', selected: "/prescription2" }
    },
    {
        path: "/prescription3",
        component: () =>
            import('../views/OrderManagement/prescription3'),
        meta: { title: '启新云方', selected: "/prescription3" }
    },
    {
        path: "/prescription",
        component: () =>
            import('../views/ReceptionPrescription/squareroot.vue'),
        meta: { title: '启新云方', selected: "/prescription3" }
    },
    {
        path: "/auditing",
        component: () =>
            import('../views/OrderManagement/auditing'),
        meta: { title: '启新云方', selected: "/auditing" }
    },
    {
        path: "/clinicRegisterInfo",
        component: () =>
            import('../views/Info/userInfo'),
        meta: { title: '启新云方', selected: "/userInfo" }
        // component: () =>
        //     import('../views/Info/clinicRegisterInfo'),
        // meta: { title: '启新云方', selected: "/clinicRegisterInfo" }
    },
    {
        path: "/userInfo",
        component: () =>
            import('../views/Info/userInfo'),
        meta: { title: '启新云方', selected: "/userInfo" }
    },
    {
        path: "/auditlist",
        component: () =>
            import('../views/PermissionCenter/auditlist'),
        meta: { title: '启新云方', selected: "/auditlist" }
    },
    {
        path: "/LogManagement",
        component: () =>
            import('../views/Info/LogManagement'),
        meta: { title: '启新云方', selected: "/LogManagement" }
    },
    {
        path: "/balance",
        component: () =>
            import('../views/Finance/balance'),
        meta: { title: '启新云方', selected: "/balance" }
    },
    {
        path: "/center",
        component: () =>
            import('../views/Finance/centerPrice'),
        meta: { title: '启新云方', selected: "/centerPrice" }
    },
    {
        path: "/hostpitalPrice",
        component: () =>
            import('../views/Finance/centerPrice'),
        meta: { title: '启新云方', selected: "/centerPrice" }
    },
    {
        path: "/fnancialOverview",
        component: () =>
            import('../views/Finance/fnancialOverview'),
        meta: { title: '启新云方', selected: "/fnancialOverview" }
    },
    {
        path: "/areaSurvey",
        component: () =>
            import('../views/Finance/areaSurvey'),
        meta: { title: '启新云方', selected: "/areaSurvey" }
    },
    {
        path: "/financemaster",
        component: () =>
            import('../views/Finance/financemaster'),
        meta: { title: '启新云方', selected: "/financemaster" }
    },
    {
        path: "/masterStock",
        component: () =>
            import('../views/Finance/masterStock'),
        meta: { title: '启新云方', selected: "/masterStock" }
    },
    {
        path: "/finance",
        component: () =>
            import('../views/Finance/finance'),
        meta: { title: '启新云方', selected: "/finance" }
    },
    {
        path: "/purchase",
        component: () =>
            import('../views/Finance/purchase'),
        meta: { title: '启新云方', selected: "/purchase" }
    },
    {
        path: "/purchase_order",
        component: () =>
            import('../views/Finance/purchaseOrder'),
        meta: { title: '启新云方', selected: "/purchaseOrder" }
    },
    {
        path: "/compare",
        component: () =>
            import('../views/Finance/compare'),
        meta: { title: '启新云方', selected: "/compare" }
    },
    {
        path: "/systemSet",
        component: () =>
            import('../views/organization/systemSet'),
        meta: { title: '启新云方', selected: "/systemSet" }
    },
    {
        path: "/countDrug",
        component: () =>
            import('../views/Statistics/countDrug'),
        meta: { title: '启新云方', selected: "/countDrug" }
    },
    {
        path: "/prescript",
        component: () =>
            import('../views/Statistics/prescript'),
        meta: { title: '启新云方', selected: "/prescript" }
    },
    {
        path: "/piecesPrescript",
        component: () =>
            import('../views/Statistics/piecesPrescript'),
        meta: { title: '启新云方', selected: "/piecesPrescript" }
    },
    {
        path: "/countDrugDetail",
        component: () =>
            import('../views/Statistics/countDrugDetail'),
        meta: { title: '启新云方', selected: "/countDrugDetail" }
    },
    {
        path: "/staffZb",
        component: () =>
            import('../views/Statistics/staffZb'),
        meta: { title: '启新云方', selected: "/staffZb" }
    },
    {
        path: "/salable",
        component: () =>
            import('../views/Statistics/salable'),
        meta: { title: '启新云方', selected: "/salable" }
    },
    {
        path: "/cash",
        component: () =>
            import('../views/Statistics/cash'),
        meta: { title: '启新云方', selected: "/cash" }
    },
    {
        path: "/orderRatio",
        component: () =>
            import('../views/Statistics/orderRatio'),
        meta: { title: '启新云方', selected: "/orderRatio" }
    },
    {
        path: "/countOrder",
        component: () =>
            import('../views/Statistics/countOrder'),
        meta: { title: '启新云方', selected: "/countOrder" }
    },
    {
        path: "/countordermaster",
        component: () =>
            import('../views/Statistics/countordermaster'),
        meta: { title: '启新云方', selected: "/countordermaster" }
    },
    {
        path: "/expressList",
        component: () =>
            import('../views/Express/expressList'),
        meta: { title: '启新云方', selected: "/expressList" }
    },
    {
        path: "/expressUrlList",
        component: () =>
            import('../views/Express/expressUrlList'),
        meta: { title: '启新云方', selected: "/expressUrlList" }
    },
    {
        path: "/expressOrder",
        component: () =>
            import('../views/Express/expressOrder'),
        meta: { title: '启新云方', selected: "/expressOrder" }
    }, {
        path: "/Reviewer",
        component: () =>
            import('../views/review/Reviewer'),
        meta: { title: '启新云方', selected: "/Reviewer" }
    }, {
        path: "/Revieweragain",
        component: () =>
            import('../views/review/Revieweragain'),
        meta: { title: '启新云方', selected: "/Revieweragain" }
    }, {
        path: "/stockoutDrugList",
        component: () =>
            import('../views/drugadministration/stockoutDrugList'),
        meta: { title: '启新云方', selected: "/stockoutDrugList" }
    },
    {
        path: "/piecesList",
        component: () =>
            import('../views/drugadministration/piecesList'),
        meta: { title: '启新云方', selected: "/piecesList" }
    },
    {
        path: "/factory",
        component: () =>
            import('../views/drugadministration/factory'),
        meta: { title: '启新云方', selected: "/factory" }
    },
    {
        path: "/inventoryDetails",
        component: () =>
            import('../views/drugadministration/inventoryDetails'),
        meta: { title: '启新云方', selected: "/inventoryDetails" }
    },
    {
        path: "/inventoryDetails/history",
        component: () =>
            import('../views/drugadministration/inventoryDetailsHistory'),
        meta: { title: '启新云方', selected: "/inventoryDetailsHistory" }
    },
    {
        path: "/CourierFee",
        component: () =>
            import('../views/drugadministration/CourierFee'),
        meta: { title: '启新云方', selected: "/CourierFee" }
    },
    {
        path: "/piecesHostpitalList",
        component: () =>
            import('../views/drugadministration/piecesHostpitalList'),
        meta: { title: '启新云方', selected: "/piecesHostpitalList" }
    },
    {
        path: "/deployList",
        component: () =>
            import('../views/review/deployList'),
        meta: { title: '启新云方', selected: "/deployList" }
    },
    {
        path: "/repetitionUserList",
        component: () =>
            import('../views/review/repetitionUserList'),
        meta: { title: '启新云方', selected: "/repetitionUserList" }
    },
    {
        path: "/InstitutionAddress",
        component: () =>
            import('../views/OrderManagement/InstitutionAddress'),
        meta: { title: '启新云方', selected: "/InstitutionAddress" }
    },
    {
        path: "/express_settings",
        component: () =>
            import('../views/OrderManagement/expressSettings'),
        meta: { title: '启新云方', selected: "/expressSettings" }
    },
    {
        path: "/order_settings",
        component: () =>
            import('../views/OrderManagement/orderSettings'),
        meta: { title: '启新云方', selected: "/orderSettings" }
    },
    {
        path: "/gnsz",
        component: () =>
            import('../views/OrderManagement/gnsz'),
        meta: { title: '启新云方', selected: "/gnsz" }
    },
    {
        path: "/prescriptionlist",
        component: () =>
            import('../views/PreManagement/prescriptionlist'),
        meta: { title: '启新云方', selected: "/prescriptionlist" }
    },
    {
        path: "/epidemicList",
        component: () =>
            import('../views/PreManagement/epidemicList'),
        meta: { title: '启新云方', selected: "/epidemicList" }
    },
    {
        path: "/branchOffice",
        component: () =>
            import('../views/Offices/branchOffice'),
        meta: { title: '启新云方', selected: "/branchOffice" }
    },
    {
        path: "/CommonFunctions",
        component: () =>
            import('../views/Offices/CommonFunctions'),
        meta: { title: '启新云方', selected: "/CommonFunctions" }
    },
    {
        path: "/blenderdrug",
        component: () =>
            import('../views/PreManagement/blenderdrug'),
        meta: { title: '启新云方', selected: "/blenderdrug" }
    },
    {
        path: "/smartDrugPreparation",
        component: () =>
            import('../views/drugadministration/smartDrugPreparation'),
        meta: { title: '启新云方', selected: "/smartDrugPreparation" }
    },
    {
        path: "/archives_info_company",
        component: () =>
            import('../views/basicData/archivesinfocompany'),
        meta: { title: '启新云方', selected: "/archivesinfocompany" }
    },
    {
        path: "/archives_info_express",
        component: () =>
            import('../views/basicData/archivesinfoexpress'),
        meta: { title: '启新云方', selected: "/archivesinfoexpress" }
    }, {
        path: "/archives_settlement_method",
        component: () =>
            import('../views/basicData/archivessettlementmethod'),
        meta: { title: '启新云方', selected: "/archivessettlementmethod" }
    },
    {
        path: "/manage_organization_item",
        component: () =>
            import('../views/manageOrganization/manageOrganizationitem'),
        meta: { title: '启新云方', selected: "/manageOrganizationitem" }
    },
    {
        path: "/cyyf",
        component: () =>
            import('../views/manageOrganization/cyyf'),
        meta: { title: '启新云方', selected: "/cyyf" }
    },
    {
        path: "/cydz",
        component: () =>
            import('../views/manageOrganization/cydz'),
        meta: { title: '启新云方', selected: "/cydz" }
    },
    {
        path: "/officePrescription",
        component: () =>
            import('../views/manageOrganization/officePrescription'),
        meta: { title: '启新云方', selected: "/officePrescription" }
    },
    {
        path: "/agent_log",
        component: () =>
            import('../views/organization/czlist'),
        meta: { title: '启新云方', selected: "/agent_log" }
    },
    {
        path: "/video",
        component: () =>
            import('../views/components/video'),
        meta: { title: '启新云方', selected: "/video" }
    },
    {
        path: "/canvas",
        component: () =>
            import('../views/components/canvas'),
        meta: { title: '启新云方', selected: "/canvas" }
    },
    {
        path: "/sftx",
        component: () =>
            import('../views/sftxs/sftx'),
        meta: { title: '启新云方', selected: "/sftx" }
    },
    {
        path: "/ajDoctor",
        component: () =>
            import('../views/AJmedicine/ajDoctor'),
        meta: { title: '启新云方', selected: "/ajDoctor" }
    },
    {
        path: "/ajPerson",
        component: () =>
            import('../views/AJmedicine/ajPerson'),
        meta: { title: '启新云方', selected: "/ajPerson" }
    },
    {
        path: "/ajCash",
        component: () =>
            import('../views/AJmedicine/ajCash'),
        meta: { title: '启新云方', selected: "/ajCash" }
    },
    {
        path: "/questionLibrary",
        component: () =>
            import('../views/xiaochengxu/questionLibrary'),
        meta: { title: '启新云方', selected: "/questionLibrary" }
    },
    {
        path: "/questionOrder",
        component: () =>
            import('../views/xiaochengxu/questionOrder'),
        meta: { title: '启新云方', selected: "/questionOrder" }
    },
    {
        path: "/smallTitle",
        component: () =>
            import('../views/xiaochengxu/smallTitle'),
        meta: { title: '启新云方', selected: "/smallTitle" }
    },
    {
        path: "/DecoctionAdministration",
        component: () =>
            import('../views/Bjkeli/DecoctionAdministration'),
        meta: { title: '启新云方', selected: "/DecoctionAdministration" }
    },
    {
        path: "/DecoctionInventory",
        component: () =>
            import('../views/Bjkeli/DecoctionInventory'),
        meta: { title: '启新云方', selected: "/DecoctionInventory" }
    },
    {
        path: "/DrugPreparation",
        component: () =>
            import('../views/Bjkeli/DrugPreparation'),
        meta: { title: '启新云方', selected: "/DrugPreparation" }
    },
    {
        path: "/OrderLists",
        component: () =>
            import('../views/Bjkeli/OrderLists'),
        meta: { title: '启新云方', selected: "/OrderLists" }
    },
    {
        path: "/ExpressLists",
        component: () =>
            import('../views/Bjkeli/ExpressLists'),
        meta: { title: '启新云方', selected: "/ExpressLists" }
    },
    {
        path: "/BusinessStatistics",
        component: () =>
            import('../views/Bjkeli/BusinessStatistics'),
        meta: { title: '启新云方', selected: "/BusinessStatistics" }
    },
    {
        path: "/DecoctionA",
        component: () =>
            import('../views/Bjkeli/DecoctionA'),
        meta: { title: '启新云方', selected: "/DecoctionA" }
    },
    
    ]
}
]

const router = createRouter({
    mode: "history",
    history: createWebHashHistory(),
    routes
})
async function leave(next) {
    try {
        await ElMessageBox.confirm(
            '您当前开方还未结束，要在离开之前保存开方信息吗?',
            {
                confirmButtonText: '保存',
                cancelButtonText: '离开',
                type: 'warning',
            }
        );
        next();
    } catch {
        next();
    }

}
// 加入全局路由守卫验证
router.beforeEach((to, from, next) => {
    // console.log(next);
    // console.log(from);
    // console.log(to);
    if (to.meta.title) {
        //判断是否有标题
        // console.log(to.meta.title)
        document.title = to.meta.title
    } else {
        document.title = '启新云方'
    }

    if (to.fullPath !== '/login' && to.fullPath !== '/doctor' && to.fullPath !== '/agent' && to.fullPath !== '/institution' && to.fullPath !== '/payList' && to.fullPath !== '/pay' && to.fullPath !== '/Phonecun' && to.fullPath !== '/Phoneuser' && to.fullPath !== '/Phonechuan' && to.fullPath !== '/Phonedan'
        && to.fullPath !== '/Phonebiao' && to.fullPath !== '/Phonegai' && to.fullPath !== '/Phonedizhi' && to.fullPath !== '/Phonedigai') {
        var token = sessionStorage.getItem("token"); //从缓存中读取数据
        if (token !== null) {
            //验证用户访问的路由规则是否在已分配的路由规则中
            // var nowpath = to.fullPath

            // if (from.fullPath == '/prescription'  ) {
            //     console.log(to.query);
            //     if (to.query.save == 'true'){
            //         next()
            //     }else {
            //         leave(next)
            //     }

            // } else {
            next()
            // }

        } else {
            next('/login')
            //  next('/pay')
        }
    } else {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("route");
        next()
        // next('/login')
    }

    if (from.path.split('/')[1] == 'viewDetails') {
        setTimeout(() => {
            location.reload();
        }, 200);
    }

})



export default router